import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import createStore from './store'

import components from "./mixins/components"
import axios from './plugins/axios'

const baseUrl = "https://" + process.env.VUE_APP_BASE_DOMAIN + "/api/wp-json/siresca/v1/"


async function initApp() {
    var sitestructure;
    if (document.querySelector("#sitestructure")) {
        sitestructure = JSON.parse(document.querySelector("#" + page + "Data").innerHTML);
    }
    else {
        sitestructure = await fetch(baseUrl + "site_structure").then(response => response.json())
    }

    const store = createStore(sitestructure);

    var app = createApp(App).use(store).mixin(components).use(axios, {
        baseUrl : baseUrl
    })

    app.use(router(app, sitestructure, store)).mount('#app')
}


initApp()