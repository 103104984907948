<template>
    <div class="textPart">
        <padder big>
            <h3>{{ pretitle }}</h3>
            <h2>{{ title }}</h2>
            <div class="text"
                 v-html="text" />
        </padder>
    </div>
</template>
<script>
export default {
    props: ["pretitle", "title", "text"]
}
</script>
<style lang="scss" scoped>
.textPart {
    width: $reduced-width;
    margin: 67px auto 54px auto;
    // width: 100%;
    max-width: 100%;

}

h3 {
    @include bigFontSize;
    font-weight: 500;
    margin-bottom: 12px;
}

h2 {
    margin-top: 0px;
}

.text {
    font-weight: 500;
    @include bigFontSize
}

@media screen and (max-width: $breakpoint) {

    h3,
    .text {
        @include defaultFontSize;
    }

    .textPart {
        margin-top: 41px;
    }
}
</style>