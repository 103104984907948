import Image from "@/components/general/Image"
import GreenLink from "@/components/general/GreenLink"
import GreyContainer from "@/components/general/GreyContainer"
import Register from "@/components/general/Register"
import TextPart from "@/components/general/TextPart"
import Anchor from "@/components/general/Anchor"
import SubNavigation from "@/components/layout/SubNavigation"
import NewsletterSignup from "@/components/general/NewsletterSignup"
import Padder from "@/components/general/Padder"

import { mapState } from "vuex"

export default {
    components: { Padder, Image, GreenLink, GreyContainer, Register, SubNavigation, TextPart, Anchor, NewsletterSignup },
    computed: {
        ...mapState({ _general: "general" }),
        currLocale() {
            return this.$route.params.locale || "de";
        },
        general() {
            return this._general[this.currLocale]
        },
        adminLink() {
            return 'https://admin.siresca.com?l=' + this.currLocale + "-CH"
        }

    },
    methods: {
        nl2br(string) {
            if (string) {

                return string.replace(/(?:\r\n|\r|\n)/g, '<br>');
            }
            else {
                // console.error("string is empty in nl2br")
                return ""
            }
        },
        localeRoute(pageid, locale) {
            if (!locale) locale = this.currLocale;
            var de_page_id = this.$store.state.init_ids[pageid].de;
            var structure = this.$store.state.sitestructure.find(p => {
                return p.de.id == de_page_id
            });


            var r = "/";
            if (locale != "de") r += locale + "/";
            if (pageid != "home")
                r += structure[locale].url;
            return r
        },
        pageName(pageid) {
            var de_page_id = this.$store.state.init_ids[pageid].de;
            var structure = this.$store.state.sitestructure.find(p => {
                return p.de.id == de_page_id
            });
            return structure[this.currLocale].name
        }
    }
}