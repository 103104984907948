<template>
    <div id="menuFooter">
        <div class="maxWidth footerContainer">
            <div class="links">
                <FooterLinks style="margin-bottom: 17px" />
                <FooterSocial />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import FooterLinks from "./FooterLinks"
import FooterSocial from "./FooterSocial"
export default {
    components: { FooterSocial, FooterLinks },
    computed: {
    }
}
</script>

<style lang="scss" scoped>
#menuFooter {
    background-color: $altBlack;
    // height: 121px;
    color: white;
    @include defaultFontSize;
    // border-top: 0.5px solid white;
    padding: 14px 10px 22px 10px;
    font-weight: 500;
    display: none;
}

@media screen and (max-width: $breakpoint) {
    .open {
        #menuFooter {
            display: block;
        }
    }
}

.footerContainer {
    display: flex;
}

.address {
    flex-grow: 2;
}

.links {
    width: 100%;
    padding-top: 7px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: center;

    &>div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
    }


}
</style>