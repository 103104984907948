<template>
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
    <path
      d="M352.84,207.07a23.84,23.84,0,0,0-28.12,18.57,127.13,127.13,0,0,1-222.29,55.57l36,2.14a23.83,23.83,0,0,0,2.84-47.57L50.3,230.36a23.82,23.82,0,0,0-25.21,22.36l-5.42,90.94A23.83,23.83,0,0,0,42,368.87c.49,0,1,0,1.44,0A23.84,23.84,0,0,0,67.24,346.5l1.85-31A174.77,174.77,0,0,0,371.41,235.2,23.83,23.83,0,0,0,352.84,207.07Z"
    />
    <path
      d="M358,31.55a23.84,23.84,0,0,0-25.21,22.37L331,84A174.8,174.8,0,0,0,50.79,109.38,173.27,173.27,0,0,0,29,164.81a23.83,23.83,0,1,0,46.69,9.56,127.12,127.12,0,0,1,222.66-55.11l-36.78-2.19a23.83,23.83,0,0,0-2.84,47.57l90.94,5.43c.49,0,1,0,1.44,0a23.84,23.84,0,0,0,23.77-22.41l5.42-90.94A23.83,23.83,0,0,0,358,31.55Z"
    />
  </svg>
</template>

<style scoped>
svg {
  fill: currentColor;
}
</style>