<template>
    <div :class="{ big: big != undefined }">
        <slot />
    </div>
</template>

<script>
export default {
    props: ["big"]
}
</script>

<style scoped lang="scss">
div {
    padding: 0px 10px;
    width: 100%;
    box-sizing: border-box;
    &.big {
        padding: 0px 20px;
    }
}
</style>