<template>
  <Header />
  <div id="pageContent">
    <router-view />
  </div>
  <Footer />
</template>
<script>
import Header from './components/layout/Header.vue';
import Footer from './components/layout/Footer.vue';
export default {
  mounted() {
  },
  components: { Header, Footer },
  computed: {

  },
  watch: {
    $route: {
      handler() {
        if (this.routename != "Base") {
          this.$loadPage("general", this.currLocale);
        }
      },
      deep: true
    }
  }
}

</script>
<style lang="scss">
// app global css in SCSS form

@font-face {
  font-family: "Swiss Intl";
  src: url(./assets/styles/fonts/SuisseIntl-Regular-WebM.woff);
  font-weight: 400;
}

@font-face {
  font-family: "Swiss Intl";
  src: url(./assets/styles/fonts/SuisseIntl-Medium-WebM.woff);
  font-weight: 500;
}

@font-face {
  font-family: "Swiss Intl";
  src: url(./assets/styles/fonts/SuisseIntl-Bold-WebM.woff);
  font-weight: 700;
}

.maxWidth {
  max-width: $max-width;
  margin: 0px auto;
}

html {
  font-size: $baseFontSize;
  font-family: "Swiss Intl";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  padding: 0px;
  margin: 0px;
  height: 100%;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  @include bigFontSize;

  &.menuOpen {
    background-color: $volt;

    #pageContent,
    footer {
      display: none;
    }
  }
}

#pageContent {
  flex-grow: 2;
  padding-top: $header-height;
  display: flex;
  flex-direction: column;
}

.navPadding {
  padding-top: $header-height;
}

a {
  color: inherit;
  text-decoration: none;
}

.inlineText a {
  color: #959595;
  text-decoration: underline;

  &:hover {
    color: black;
  }
}



h1,
h2,
h3,
h4 {
  font-weight: 500;
}

h2 {
  @include bigTitleFontSize;
  font-weight: 500;
  overflow-wrap: break-word;
  hyphens: auto;
  width: 100%;
}

@media screen and (max-width: $breakpoint) {
  h2 {
    font-size: $mobileBigFont;
  }

  #app {
    @include defaultFontSize;
  }
}
</style>
