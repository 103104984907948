export default {
    about: { de: 30, fr: 454, it: 466 },
    agb: { de: 55, fr: 442, it: 457 },
    contact: { de: 32, fr: 444, it: 458 },
    datenschutz: { de: 57, fr: 446, it: 459 },
    general: { de: 45, fr: 448, it: 493 },
    home: { de: 2, fr: 487, it: 461 },
    impressum: { de: 59, fr: 450, it: 462 },
    notfound: {
        de: 260, fr: 438, it: 456
    },
    pricing: { de: 28, fr: 451, it: 463 },
    product: { de: 48, fr: 452, it: 464 },
    support: { de: 258, fr: 453, it: 465 },
    vorteile: { de: 26, fr: 504, it: 506 },
}