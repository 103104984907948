
<template>
    <div ref="container"
         v-html="htmlContent"></div>
</template>

<script>
export default {
    props: {
        htmlContent: {
            type: String,
            required: true
        }
    },

    watch: {
        htmlContent: {
            handler() {
                this.$nextTick(() => {
                    const anchors = this.$refs.container.getElementsByTagName('a') // Not iterable

                    Array.from(anchors).forEach(anchor => {
                        const url = anchor.getAttribute('href')
                        if (!url.startsWith('/'))
                            return
                        const propsData = { path: url }
                        anchor.addEventListener("click", (e) => {
                            e.preventDefault();
                            console.log(propsData)
                            this.$router.push(propsData)
                        })
                    })
                })

            },
            immediate: true,
        }
    }
}
</script>