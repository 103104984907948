<template>
    <padder>
        <div :class="'greyContainer '+specialclass">
            <div class="content">
                <slot></slot>
            </div>
        </div>
    </padder>
</template>

<script>
export default {
    props: ["specialclass"]
}
</script>
<style lang="scss" scoped>
.greyContainer {
    background-color: #F2F2F2;
    border-radius: 20px;
    max-width: $max-width+100px;
    margin: 0px auto;
    padding: 25px;
    box-sizing: border-box;

    .content {
        max-width: $max-width;
        margin: 0px auto;
    }
}
</style>