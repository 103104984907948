<template>
    <footer>
        <div class="maxWidth footerContainerDesktop">
            <div class="address"
                 v-html="nl2br(general.footer_address)" />
            <div class="links">
                <FooterSocial style="flex-grow: 2;text-align:right" />
                <FooterLinks />
            </div>
        </div>
        <div class="maxWidth footerContainerMobile">
            <div class="left">
                <div class="address"
                     v-html="nl2br(general.footer_address)" />
                <FooterLinks class="linksMobile"
                             style="display: flex;flex-wrap: wrap;gap: 20px;" />
            </div>
            <FooterSocial style="display: flex;flex-direction: column; gap: 10px;" />
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';
import FooterLinks from "./FooterLinks"
import FooterSocial from "./FooterSocial"
export default {
    components: { FooterSocial, FooterLinks },
    computed: {
    }
}
</script>

<style lang="scss" scoped>
footer {
    background-color: $altBlack;
    // height: 121px;
    color: white;
    @include defaultFontSize;
    // border-top: 0.5px solid white;
    padding: 14px 10px 22px 10px;
    font-weight: 500;
}

.footerContainerDesktop {
    display: flex;
}

.address {
    flex-grow: 2;
}

.links {
    padding-top: 7px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    a {
        margin-left: 20px;
    }

    &>div {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
    }
}

.footerContainerMobile {
    display: none;
}

@media screen and (max-width: $breakpoint) {
    .footerContainerDesktop {
        display: none;
    }

    .footerContainerMobile {
        display: flex;

        .left {
            flex-grow: 2;
        }
    }

    .linksMobile a {
        margin-right: 20px;
        margin-bottom: 10px;
    }

    .address {
        margin-bottom: 46px;
    }
}
</style>