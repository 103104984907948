<template>
  <svg
    version="1.1"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2123.5 250"
    style="enable-background:new 0 0 2123.5 250;"
    xml:space="preserve"
  >
    <path
      d="M1257,0h250l-36.5,63.5h-209l-17.5,30h157c16.5,0,30,13.5,30,30c0,5.5-1.5,10.5-4,15l-45.5,78c-11.5,20-27,33.5-57,33.5H782
	c-16.5,0-30-13.5-30-30c0-5.5,1.5-10.5,4-15l82-141.5H686L577.5,250H495L620.5,33.5c11.5-20,27-33.5,57-33.5h428
	c16.5,0,30,13.5,30,30c0,5.5-1.5,10.5-4,15l-45.5,78c-11.5,20-27,33.5-57,33.5H862.5l-17.5,30h475l17.5-30H1181
	c-16.5,0-30-13.5-30-30c0-5.5,1.5-10.5,4-15l45-78C1211.5,13.5,1227,0,1257,0z M250,250H0l37-63.5h208.5l17.5-30H111.5
	c-21.5,0-35-13.5-35-30c0-5.5,1.5-10.5,4-15l45-78c11.5-20,27-33.5,57-33.5h250L396,63.5H187.5l-17.5,30h156.5c16.5,0,30,13.5,30,30
	c0,5.5-1.5,10.5-4,15l-45.5,78C295.5,236.5,280,250,250,250z M1831.5,186.5l17.5-30h129l-17.5,30H1831.5z M2020.5,215.5l99-170.5
	c2.5-4.5,4-9.5,4-15c0-16.5-13.5-30-30-30h-233L1824,63.5h206.5l-17.5,30h-169c-30,0-45.5,14-57,34l-34.5,59h-215l71.5-123h155
	L1800.5,0H1602c-30,0-45.5,13.5-57,33.5L1445.5,205c-2.5,4.5-4,9.5-4,15c0,16.5,13.5,30,30,30h492
	C1993.5,250,2009,235.5,2020.5,215.5z M899.5,93.5H1025l17.5-30h-126L899.5,93.5z M348.5,250h84l145-250h-84L348.5,250z"
    />
  </svg>
</template>