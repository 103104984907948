<template>
    <div class="anchor"
       :id="hash">&nbsp;</div>
</template>

<script>

export default {
    props: ["hash", "extra"]
}
</script>

<style scoped lang="scss">
div.anchor {
    display: block;
    position: relative;
    top: -2*$header-height;
    visibility: hidden;
    height: 0px;
}
</style>