<template>
    <div class="signupContainer">

        <padder big
                style="height:100%">
            <div class="verticalAligner">
                <template v-if="!success">
                    <h2>{{ general.newsletter_registration }}</h2>
                    <form class="signupForm"
                          ref="signupForm"
                          :class="{ error }"
                          @submit.prevent="submitForm">
                        <div class="label">{{ general.newsletter_registration_label }}</div>
                        <div class="textInputContainer">
                            <input type="text"
                                   id="email"
                                   :placeholder="general.newsletter_registration_label"
                                   ref="emailInput"
                                   name="email"
                                   @keyup="updateEmail">
                            <SSpinner v-if="sending"
                                      class="spinner"
                                      spincolor="rgba(0, 0, 0, 0.3)" />
                            <!-- <img src="../../assets/icons/Not_OK.svg"
                                 v-else-if="error"> -->
                            <img src="../../assets/icons/OK.svg"
                                 v-else-if="!error && currEmail != ''">
                        </div>
                        <input type="checkbox"
                               name="confirm"
                               style="display: none;">
                        <div></div>
                        <div></div>

                        <input type="submit"
                               :value="general.newsletter_button_text"
                               :disabled="sending || error || currEmail == ''">

                        <div></div>
                    </form>
                </template>
                <template v-else>
                    <h2>{{ general.newsletter_success }}</h2>
                </template>
            </div>
        </padder>
    </div>
</template>
<script>
import SSpinner from '../icon/SSpinner.vue'

export default {
    data() {
        return {
            currEmail: "",
            sending: false,
            success: false
        };
    },
    computed: {
        error() {
            if (this.currEmail == "") {
                return false;
            }
            else if (!this.validateEmail(this.currEmail)) {
                return true;
            }
            else
                return false;
        },
    },
    methods: {
        updateEmail(e) {
            // console.log(e.target.value)
            this.currEmail = e.target.value;
            console.log(this.currEmail);
        },
        validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
        submitForm(e) {
            const formData = new FormData(this.$refs.signupForm);
            this.sending = true;
            this.$post("addemail", formData)
                .then((response) => {
                    this.sending = false;
                    this.success = true;
                })
                .catch((response) => {
                    //handle error
                    console.log(response);
                });
        }
    },
    components: { SSpinner }
}
</script>
<style lang="scss" scoped>
h2 {
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: center;
}

.signupContainer {
    background-color: $altBlack;
    width: 100%;
    color: white;
    height: 500px;
}

.verticalAligner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.signupForm {
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    width: 500px;
    max-width: 100%;
    margin: 0px auto;
    line-height: 33px;
    @include defaultFontSize;
    font-weight: 500;
}

input {
    height: 33px;
    line-height: 33px;
    font-family: "Swiss Intl";
    border-radius: 3px;
    border: unset;
    outline: unset;

    &[type=text] {
        padding: 0px 10px;
        box-sizing: border-box;
        width: 100%;

    }
}

input[type=submit] {
    margin-top: 8px;
    font-weight: 500;
    background-color: $volt;
    cursor: pointer;

    &:hover {
        background-color: $voltHover;
    }

    &:disabled {
        pointer-events: none;
    }
}

.error {

    input[type=text] {
        // color: red;
    }
}

.textInputContainer {
    position: relative;

    img,
    .spinner {
        color: rgba(0, 0, 0, 0.7);
        position: absolute;
        right: 5px;
        top: 5px;
        height: 23px;
        width: auto;
    }
}

@media screen and (max-width: $breakpoint) {

    .signupForm {
        width: 100%;
        display: block;

        .label {
            display: none;
        }

        input {
            width: 100%;
        }

        input::placeholder {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.3);
            opacity: 1;
        }
    }
}

@media screen and (min-width: $breakpoint) {
    input::placeholder {
        display: none;
        opacity: 0;
    }
}
</style>