<template>
    <div class="socialLinksContainer">
        <a class="socialLink"
           :href="link.url"
           v-for="link in general.social_links">
            <Image :img="link.logo_image" />
        </a>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {

}
</script>

<style lang="scss" scoped>
.socialLink {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 20px;
    display: inline-block;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>