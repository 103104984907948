<template>
    <div class="linksContainer">
        <a class="socialLink"
           :href="link.url"
           v-for="link in general.other_links">
            {{ link.text }}
        </a>

        <router-link :to="localeRoute('impressum')">{{pageName('impressum')}}</router-link>
        <router-link :to="localeRoute('datenschutz')">{{pageName('datenschutz')}}</router-link>
        <router-link :to="localeRoute('agb')">{{pageName('agb')}}</router-link>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {

}
</script>